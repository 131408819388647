// a list of tokens by chain
import { Currency } from '@uniswap/sdk-core'
import { Token } from './token'
import { SupportedChainId } from './chains'
import {
  STC,
  STAR,
  FAI,
  XUSDT,
  XETH,
  WEN,
  SHARE,
  AMPL,
  DAI,
  ExtendedStar,
  FEI,
  FRAX,
  FXS,
  MIR,
  renBTC,
  TRIBE,
  UMA,
  UNI,
  USDC,
  USDT,
  UST,
  WBTC,
  ETH2X_FLI,
  WETH9_EXTENDED,
} from './tokens'

import TOKEN_LIST from '../constants/starswap-default.tokenlist.json'
import { CONTRACT_ADDRESS } from '../constants/addresses'

type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

type ChainCurrencyList = {
  readonly [chainId: number]: Currency[]
}

const WETH_ONLY: ChainTokenList = {
  [SupportedChainId.MAINNET]: [WETH9_EXTENDED[SupportedChainId.MAINNET]],
  [SupportedChainId.ROPSTEN]: [WETH9_EXTENDED[SupportedChainId.ROPSTEN]],
  [SupportedChainId.RINKEBY]: [WETH9_EXTENDED[SupportedChainId.RINKEBY]],
  [SupportedChainId.GOERLI]: [WETH9_EXTENDED[SupportedChainId.GOERLI]],
  [SupportedChainId.KOVAN]: [WETH9_EXTENDED[SupportedChainId.KOVAN]],
  [SupportedChainId.ARBITRUM_ONE]: [WETH9_EXTENDED[SupportedChainId.ARBITRUM_ONE]],
}
// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  // ...WETH_ONLY,
  // [1]: [...WETH_ONLY[1], DAI, USDC, USDT, WBTC],
  [SupportedChainId.MAINNET]: [STC[SupportedChainId.MAINNET]],
  [SupportedChainId.BARNARD]: [STC[SupportedChainId.BARNARD]],
  [SupportedChainId.PROXIMA]: [STC[SupportedChainId.PROXIMA]],
}
export const ADDITIONAL_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  // [1]: {
  //   ...mAssetsAdditionalBases,
  //   '0xF16E4d813f4DcfDe4c5b44f305c908742De84eF0': [ETH2X_FLI],
  //   '0xA948E86885e12Fb09AfEF8C52142EBDbDf73cD18': [UNI[1]],
  //   '0x561a4717537ff4AF5c687328c0f7E90a319705C0': [UNI[1]],
  //   '0xE0360A9e2cdd7d03B9408c7D3001E017BAc2EcD5': [UNI[1]],
  //   '0xa6e3454fec677772dd771788a079355e43910638': [UMA],
  //   '0xB46F57e7Ce3a284d74b70447Ef9352B5E5Df8963': [UMA],
  //   [FEI.address]: [TRIBE],
  //   [TRIBE.address]: [FEI],
  //   [FRAX.address]: [FXS],
  //   [FXS.address]: [FRAX],
  //   [WBTC.address]: [renBTC],
  //   [renBTC.address]: [WBTC],
  // }
}
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  // [1]: {
  //   [AMPL.address]: [DAI, WETH9_EXTENDED[1]],
  // },
}

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  // [1]: [ExtendedStar.onChain(1), DAI, USDC, USDT, WBTC, WETH9_EXTENDED[1]],
  [SupportedChainId.MAINNET]: [ExtendedStar.onChain(SupportedChainId.MAINNET), STAR[SupportedChainId.MAINNET], FAI[SupportedChainId.MAINNET], WEN[SupportedChainId.MAINNET], XUSDT[SupportedChainId.MAINNET]],
  [SupportedChainId.BARNARD]: [ExtendedStar.onChain(SupportedChainId.BARNARD), STAR[SupportedChainId.BARNARD], FAI[SupportedChainId.BARNARD], WEN[SupportedChainId.BARNARD], SHARE[SupportedChainId.BARNARD], XUSDT[SupportedChainId.BARNARD], XETH[SupportedChainId.BARNARD]],
  [SupportedChainId.PROXIMA]: [ExtendedStar.onChain(SupportedChainId.PROXIMA), STAR[SupportedChainId.PROXIMA], FAI[SupportedChainId.PROXIMA], WEN[SupportedChainId.PROXIMA], SHARE[SupportedChainId.PROXIMA], XUSDT[SupportedChainId.PROXIMA], XETH[SupportedChainId.PROXIMA]],
  [3]: [ExtendedStar.onChain(3), WETH9_EXTENDED[3]],
  [4]: [ExtendedStar.onChain(4), WETH9_EXTENDED[4]],
  [5]: [ExtendedStar.onChain(5), WETH9_EXTENDED[5]],
  [42]: [ExtendedStar.onChain(42), WETH9_EXTENDED[42]],
  [SupportedChainId.ARBITRUM_ONE]: [
    ExtendedStar.onChain(SupportedChainId.ARBITRUM_ONE),
    WETH9_EXTENDED[SupportedChainId.ARBITRUM_ONE],
  ],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  // [1]: [...WETH_ONLY[1], DAI, USDC, USDT, WBTC],
  [SupportedChainId.MAINNET]: [ExtendedStar.onChain(SupportedChainId.MAINNET).wrapped],
  [SupportedChainId.BARNARD]: [ExtendedStar.onChain(SupportedChainId.BARNARD).wrapped],
  [SupportedChainId.PROXIMA]: [ExtendedStar.onChain(SupportedChainId.PROXIMA).wrapped],
}

interface TokenProp {
  chainId: number,
  address: string,
  name: string,
  symbol: string,
  decimals: number,
  logoURI: string
}

const getPairs = (tokens: TokenProp[]) => {
  const pairs: [Token, Token][] = []

  const hash: {
    [key: string]: Token
  } = {}

  for (let i = 0; i < tokens.length - 1; i++) {
    let token0: Token

    if (hash[tokens[i].address]) {
      token0 = hash[tokens[i].address]
    } else {
      token0 = new Token(SupportedChainId.APTOS, tokens[i].address, tokens[i].decimals, tokens[i].symbol, tokens[i].name)

      hash[tokens[i].address] = token0
    }

    for (let j = i + 1; j < tokens.length; j++) {
      let token1: Token

      if (hash[tokens[j].address]) {
        token1 = hash[tokens[j].address]
      } else {
        token1 = new Token(SupportedChainId.APTOS, tokens[j].address, tokens[j].decimals, tokens[j].symbol, tokens[j].name)

        hash[tokens[j].address] = token1
      }

      pairs.push([token0, token1])
    }
  }

  return pairs
}

export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [SupportedChainId.APTOS]: getPairs(TOKEN_LIST.tokens)
}