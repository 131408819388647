import useSWR from 'swr'
import axios from 'axios'
import { useEffect } from 'react'
import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@starcoin/starswap-v2-sdk'
import { useStarcoinProvider } from './useStarcoinProvider'
import getCurrentNetwork from '../utils/getCurrentNetwork'
import { useLiquidityPools } from '../state/user/hooks'
import useConnection from './useConnection'
import useWeb3 from './web3'
import { CONTRACT_ADDRESS } from 'constants/addresses'

const PREFIX = `${V2_FACTORY_ADDRESS}::TokenSwapRouter::`

/**
 * 查询当前签名者在某代币对下的流动性
 */
export function useUserLiquidity(signer?: string, x?: string, y?: string) {
  const provider = useStarcoinProvider()
  return useSWR(
    signer && x && y ? [provider, 'liquidity', signer, x, y] : null,
    async () =>
      (await provider.callV2({
        function_id: `${PREFIX}liquidity`,
        type_args: [x!, y!],
        args: [signer!],
      })) as [number]
  )
}

/**
 * 查询某Token代币对总流动性
 */
export function useTotalLiquidity(x?: string, y?: string) {
  const { connection } = useConnection()
  const fetcher = async () => {
    // @ts-ignore
    const result = await connection.getAccountResource(
      `${CONTRACT_ADDRESS}`,
      `0x1::coin::CoinInfo<${CONTRACT_ADDRESS}::TokenSwap::LiquidityToken<${x},${y}>>`
    )

    // @ts-ignore
    return result?.data?.supply?.vec[0].integer.vec[0].value
  }

  return useSWR(
    x && y ? [connection, 'getAccountResource', x, y] : null,
    fetcher
  )
}

/**
 * 查询代币对池中的总额度
 */
// export function useGetReserves(x?: string, y?: string) {
//   const provider = useStarcoinProvider()
//   return useSWR(
//     x && y ? [provider, 'get_reserves', x, y] : null,
//     async () =>
//       (await provider.call({
//         function_id: `${PREFIX}get_reserves`,
//         type_args: [x!, y!],
//         args: [],
//       })) as [number, number]
//   )
// }

async function useGetReserves(provider: any, pair: any) {
  let result
  try {
    result = await provider.call({
      function_id: `${PREFIX}get_reserves`,
      type_args: pair,
      args: [],
    })
  } catch (error) {
    // will fail if pair is not exists on chain 
    result = [0, 0]
  }
  return result
}

const getPairResourceAddress = (pair: [string, string]) => {
  const map: { [key: string]: string } = {}
  const tokens: string[] = []

  // TODO
  // get pair address from tokens
  pair.forEach((address: string) => {
    const [constract, coin, symbol] = address.split('::')

    tokens.push(symbol)

    map[symbol] = address
  })

  const [token0, token1] = tokens.sort()

  return `${CONTRACT_ADDRESS}::TokenSwap::TokenSwapPair<${map[token0]},${map[token1]}>`
}

export function useBatchGetReserves(pairs: ([string, string] | undefined)[]) {
  const { connection } = useConnection()

  return useSWR(
    pairs.length ? pairs : null,  // convert array to string as key for caching
    () => Promise.all(pairs.map((pair) =>
      pair && pair.length === 2 ?
        connection.getAccountResource(
          `${CONTRACT_ADDRESS}`,
          getPairResourceAddress(pair)
        )
        : Promise.resolve(null)
    ))
  )
}

export function useGetLiquidityPools() {
  const { chainId } = useWeb3()
  const [liquidityPools, setLiquidityPools] = useLiquidityPools()

  useEffect(
    () => {
      if (!liquidityPools[chainId]) {
        const url = `https://swap-api.starcoin.org/main/v1/liquidityPools`
        axios.get(url).then((res: any) => res.data).then((data: any) => setLiquidityPools({ ...liquidityPools, [chainId]: data }))
      }
    },
    [chainId, liquidityPools, setLiquidityPools]
  )

  return null//liquidityPools[chainId]
}

/**
 * 根据x计算y (无手续费)
 */
// export function useQuote(amount_x?: number | string, reverse_x?: number, reverse_y?: number) {
//   const provider = useStarcoinProvider()
//   return useSWR(
//     amount_x && reverse_x && reverse_y ? [provider, 'quote', amount_x, reverse_x, reverse_y] : null,
//     async () =>
//       (await provider.call({
//         function_id: `${PREFIX}quote`,
//         type_args: [],
//         args: [`${amount_x!.toString()}u128`, `${reverse_x!.toString()}u128`, `${reverse_y!.toString()}u128`],
//       })) as [number]
//   )
// }

/**
 * 根据换入额度计算换出额度，固定千分之三手续费
 */
// export function useGetAmountOut(amount_in?: number | string, reverse_in?: number, reverse_out?: number) {
//   const provider = useStarcoinProvider()
//   return useSWR(
//     amount_in && reverse_in && reverse_out ? [provider, 'get_amount_out', amount_in, reverse_in, reverse_out] : null,
//     async () =>
//       (await provider.call({
//         function_id: `${PREFIX}get_amount_out`,
//         type_args: [],
//         args: [`${amount_in!.toString()}u128`, `${reverse_in!.toString()}u128`, `${reverse_out!.toString()}u128`],
//       })) as [number]
//   )
// }

/**
 * 根据换出额度计算换入额度，固定千分之三手续费
 */
// export function useGetAmountIn(amount_out?: number | string, reverse_in?: number, reverse_out?: number) {
//   const provider = useStarcoinProvider()
//   return useSWR(
//     amount_out && reverse_in && reverse_out ? [provider, 'get_amount_in', amount_out, reverse_in, reverse_out] : null,
//     async () =>
//       (await provider.call({
//         function_id: `${PREFIX}get_amount_in`,
//         type_args: [],
//         args: [`${amount_out!.toString()}u128`, `${reverse_in!.toString()}u128`, `${reverse_out!.toString()}u128`],
//       })) as [number]
//   )
// }
