import { useEffect, useState } from 'react'
import { useWeb3 } from "context/Web3Provider";

export function useEagerConnect() {
  const { active, setConnector } = useWeb3() // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false)

  useEffect(() => {
    if (window.martian && !active) {
      setConnector(window.martian)
      setTried(true)
    } else {
      setTried(true)
    }
  }, [active, setConnector]) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}


export default useWeb3