import { useState, useMemo, useContext, createContext, useEffect } from 'react'
import { AptosClient } from "aptos";

import { NODE_URL } from 'constants/connection'

interface ConnectionProps {
  connection: AptosClient;
  endpoint: string;
}

export const ConnectionContext = createContext<ConnectionProps>({
  connection: new AptosClient(NODE_URL),
  endpoint: NODE_URL,
})

export const useConnection = () => useContext(ConnectionContext)

const ConnectionProvider:  React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [endpoint, setEndpoint] = useState(NODE_URL)

  const connection: AptosClient = useMemo(
    () => new AptosClient(endpoint),
    [endpoint]
  )

  return <ConnectionContext.Provider 
    value={{
      connection,
      endpoint
    }}
  >
    {children}
  </ConnectionContext.Provider>
}

export default ConnectionProvider