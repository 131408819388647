import { useState, useCallback, useContext, useEffect, createContext } from 'react'

import { CHAIN_ID } from '../constants/chains'

interface Web3ProviderProps {
  chainId: number,
  account: string,
  address: string,
  connected: boolean,
  active: boolean,
  error: any,
  connector: any,
  connect: () => void,
  disconnect: () => void,
  setConnector: (connector: any) => void,
  resources: any[]
}

export const Web3Context = createContext<Web3ProviderProps>({
  chainId: CHAIN_ID,
  account: '',
  address: '',
  connected: false,
  active: false,
  error: null,
  connector: null,
  connect: () => {},
  disconnect: () => {},
  setConnector: () => {},
  resources: []
})

export const useWeb3 = () => useContext(Web3Context)

const Web3Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [account, setAccount] = useState('')
  const [address, setAddress] = useState('')
  const [connected, setConnected] = useState(false)
  const [active, setActive] = useState(false)
  const [connector, setConnector] = useState<any>(null)
  const [error, setError] = useState(false)
  const [chainId, setChainId] = useState(CHAIN_ID)
  const [resources, setResources] = useState<any[]>([])

  const connect = useCallback(async () => {
    if (connector) {
      try {
        const { address, publicKey } = await connector.connect()
        setConnected(true)
        setAddress(publicKey)
        setAccount(address)
        setActive(true)
        const resources = await connector.getAccountResources(address);
        setResources(resources)
        // console.log(`user resources`, resources)
      } catch (error: any) {
        console.error(error)
        setError(error)
      }
    }
  }, [connector])

  const disconnect = useCallback(async () => {
    if (connector) {
      await connector.disconnect()
      setConnected(false)
      setAddress('')
      setAccount('')
      setActive(false)
    }
  }, [connector])

  useEffect(() => {
    if (connector) {
      connect()

      connector.getChainId().then(({ chainId }: { chainId: number }) => setChainId(chainId))
    }

    return () => {
      disconnect()
    }
  }, [connector, connect, disconnect])

  return (
    <Web3Context.Provider
      value={{
        chainId,
        account,
        address,
        connected,
        active,
        connector,
        error,
        setConnector,
        connect,
        disconnect,
        resources
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}

export default Web3Provider