import { bcs, utils } from '@starcoin/starcoin'
import { arrayify, hexlify } from '@ethersproject/bytes'
import { Token } from '@starcoin/starswap-sdk-core'
import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@starcoin/starswap-v2-sdk'
import { useCallback } from 'react'
import { useStarcoinProvider } from './useStarcoinProvider'
import { TransactionPayloadVariantScriptFunction } from '@starcoin/starcoin/dist/src/lib/runtime/starcoin_types'
import { useTransactionExpirationSecs } from './useTransactionDeadline'
import useConnection from './useConnection'
import { CONTRACT_ADDRESS } from 'constants/addresses'
import useWeb3 from './web3'

const PREFIX = `${CONTRACT_ADDRESS}::TokenSwapScripts::`

function serializeU128(value: string | number): string {
  const se = new bcs.BcsSerializer()
  se.serializeU128(BigInt(value))
  return hexlify(se.getBytes())
}

function serializeScriptFunction(scriptFunction: TransactionPayloadVariantScriptFunction) {
  const se = new bcs.BcsSerializer()
  scriptFunction.serialize(se)
  return hexlify(se.getBytes())
}

export function useRegisterSwapPair(signer?: string) {
  const provider = useStarcoinProvider()
  return useCallback(
    async (x: string, y: string) => {
      const functionId = `${PREFIX}register_swap_pair`
      const tyArgs = utils.tx.encodeStructTypeTags([x, y])
      const args: Uint8Array[] = []
      const scriptFunction = utils.tx.encodeScriptFunction(functionId, tyArgs, args)
      const transactionHash = await provider.getSigner(signer).sendUncheckedTransaction({
        data: serializeScriptFunction(scriptFunction),
      })
      return transactionHash
    },
    [provider, signer]
  )
}

/**
 * 通过指定换入的代币额度来置换代币
 */
export function useSwapExactTokenForToken(signer?: string) {
  const { account, connector: provider } = useWeb3()

  return useCallback(
    async (x: string, y: string, midPath: Token[], amount_x_in: number | string, amount_y_out_min: number | string) => {
      const sender = account
      const payload = {
        function: `${PREFIX}swap_exact_token_for_token`,
        type_arguments: [x, y],
        arguments: [amount_x_in, amount_y_out_min]
      };

      const transactionHash = await provider.generateSignAndSubmitTransaction(sender, payload);

      return transactionHash
    },
    [provider, account]
  )
}

/**
 * 通过指定换出的代币额度来置换代币
 */
export function useSwapTokenForExactToken(signer?: string) {
  const { account, connector: provider } = useWeb3()

  return useCallback(
    async (x: string, y: string, midPath: Token[], amount_x_in_max: number | string, amount_y_out: number | string) => {
      const sender = account
      const payload = {
        function: `${PREFIX}swap_token_for_exact_token`,
        type_arguments: [x, y],
        arguments: [amount_x_in_max, amount_y_out]
      };

      const transactionHash = await provider.generateSignAndSubmitTransaction(sender, payload);

      return transactionHash
    },
    [provider, account]
  )
}

/**
 * 添加流动性，需要在调用 register_swap_pair 之后才可调用
 */
export function useAddLiquidity(signer?: string) {
  const { account, connector: provider } = useWeb3()

  return useCallback(
    async (
      x: string,
      y: string,
      amount_x_desired: number | string,
      amount_y_desired: number | string,
      amount_x_min: number | string,
      amount_y_min: number | string
    ) => {
      const sender = account
      const payload = {
        function: `${PREFIX}add_liquidity`,
        type_arguments: [x, y],
        arguments: [amount_x_desired, amount_y_desired, amount_x_min, amount_y_min]
      };

      console.log(`payload`, JSON.stringify(payload))
      const transactionHash = await provider.generateSignAndSubmitTransaction(sender, payload);

      return transactionHash

    },
    [provider, account]
  )
}

/**
 * 移除流动性，需要在调用 register_swap_pair 之后才可调用
 */
export function useRemoveLiquidity(signer?: string) {
  const { account, connector: provider } = useWeb3()

  return useCallback(
    async (
      x: string,
      y: string,
      liquidity: number | string,
      amount_x_min: number | string,
      amount_y_min: number | string
    ) => {
      const sender = account
      const payload = {
        function: `${PREFIX}remove_liquidity`,
        type_arguments: [x, y],
        arguments: [liquidity, amount_x_min, amount_y_min]
      };

      const transactionHash = await provider.generateSignAndSubmitTransaction(sender, payload);

      return transactionHash
    },
    [provider, account]
  )
}
