import { Currency, Token, CurrencyAmount } from '@starcoin/starswap-sdk-core'
import { FACTORY_ADDRESS } from '@starcoin/starswap-v2-sdk'
import JSBI from 'jsbi'
import { useMemo } from 'react'
import useWeb3 from '../../hooks/web3'
import { useAllTokens } from '../../hooks/Tokens'

import useSWR from 'swr'

import { APTOS_COIN_STORE } from '../../constants/tokens'

import useConnection from 'hooks/useConnection'
import { CONTRACT_ADDRESS } from 'constants/addresses'

/**
 * Returns a map of the given addresses to their eventually consistent ETH balances.
 */
export function useAptosBalances(uncheckedAddresses?: (string | undefined)[]): {
  [address: string]: CurrencyAmount<Currency> | undefined
} {
  const { connector } = useWeb3()
  const allTokens = useAllTokens()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
          // .map(isAddress)
          .filter((a): a is string => !!a)
          .sort()
        : [],
    [uncheckedAddresses]
  )

  const { data: results } = useSWR(addresses.length ? addresses : null, () =>
    Promise.all(addresses.map((address) => connector.getAccountResources(address)))
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount<Currency> }>((memo, address, i) => {
        const result: any[] = results ? results[i] : null

        if (result && Object.keys(allTokens).length) {
          const aptosCoin = result.find(({ type }) => type === APTOS_COIN_STORE)
          const value = aptosCoin?.data?.coin?.value

          if (value && allTokens['0x1::aptos_coin::AptosCoin']) {
            memo[address] = CurrencyAmount.fromRawAmount(allTokens['0x1::aptos_coin::AptosCoin'], value)
          }
        }

        return memo
      }, {}),
    [addresses, results, allTokens]
  )
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[]
): [{ [tokenAddress: string]: CurrencyAmount<Token> | undefined }, boolean] {
  const { connection } = useConnection()

  // @ts-ignore
  const fetcher = async (): Promise<{
    [key: string]: any
  }> => {
    if (address) {
      const resources = await connection.getAccountResources(address)

      return resources.reduce((map, cur) => ({
        ...map,
        [cur.type]: cur
      }), {})
    } else {
      return {}
    }
  }
  const { data: resources, isValidating } = useSWR(address ? [connection, 'getAccountResources', address] : null, fetcher)

  // @ts-ignore
  return [
    useMemo(() => address && tokens && tokens.length && resources ?
      tokens.reduce<{ [tokenAddress: string]: CurrencyAmount<Token> | undefined }>((memo, token, i) => {
        if (token) {
          // TODO
          // should not use starswap FACTORY_ADDRESS
          const type = `0x1::coin::CoinStore<${token.address.replace(FACTORY_ADDRESS, CONTRACT_ADDRESS)}>`
          const coinStore = resources[type]

          const value = coinStore?.data?.coin?.value
          const amount = value ? JSBI.BigInt(value.toString()) : undefined

          if (amount) {
            memo[token.address] = CurrencyAmount.fromRawAmount(token, amount)
          }
        }

        return memo
      }, {})
      : {}, [address, tokens, resources]),
    isValidating
  ]
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[]
): { [tokenAddress: string]: CurrencyAmount<Token> | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: Token): CurrencyAmount<Token> | undefined {
  const tokenBalances = useTokenBalances(account, [token])
  console.log(`tokenBalances`, tokenBalances)

  if (!token) {
    return undefined
  }

  return tokenBalances[token.address]
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[]
): (CurrencyAmount<Currency> | undefined)[] {
  const tokens = useMemo(
    () => currencies?.filter((currency): currency is Token => currency?.isToken ?? false) ?? [],
    [currencies]
  )

  const tokenBalances = useTokenBalances(account, tokens)

  return useMemo(
    () =>
      currencies?.map((currency) => {
        if (!account || !currency) return undefined
        if (currency.isToken) return tokenBalances[currency.address]
        return undefined
      }) ?? [],
    [account, currencies, tokenBalances]
  )
}

export function useCurrencyBalance(account?: string, currency?: Currency): CurrencyAmount<Currency> | undefined {
  return useCurrencyBalances(account, [currency])[0]
}

// mimics useAllBalances
export function useAllTokenBalances(): { [tokenAddress: string]: CurrencyAmount<Token> | undefined } {
  const { account } = useWeb3()
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])

  const balances = useTokenBalances(account ?? undefined, allTokensArray)

  return balances ?? {}
}

