import MARTIAN_ICON_URL from '../assets/images/martian.svg'

export interface WalletInfo {
  connector?: any
  name: string
  iconURL: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  MARTIAN: {
    connector: window.martian,
    name: 'Martian',
    iconURL: MARTIAN_ICON_URL,
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
}
